import React, { ComponentType, lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/guards/AuthGuard';
import NucleusDashboard from './custom/layouts/Dashboard';
import NewLead from './custom/pages/NewLead';
import NewContact from './custom/pages/NewContact';
import NucleusLoader from './custom/NucleusLoader';
import UserProfile from './custom/pages/userProfile/UserProfile';
import Voicemail from './custom/pages/Voicemail';
import MissedCall from './custom/pages/MissedCall';
import AvailableTwilioNumbersList from './custom/pages/admin/twilio/phoneNumbers/AvailableTwilioNumbersList';
import PermissionGuard from './components/guards/PermissionGuard';
import PurchasedNumbersList from './custom/pages/admin/twilio/phoneNumbers/PurchasedNumbersList';
import CallActivity from './custom/pages/CallActivity';

const AuthLayout = lazy(() => import('./layouts/Auth'));
const ProjectMenuNew = lazy(() => import('./custom/components/ProjectMenuNew'));
const BlankLayout = lazy(() => import('./layouts/Blank'));

// @ts-ignore
const ViewProposal = lazy(() => import('./custom/pages/project/proposal/ProposalMainComponent'));

const SignIn = lazy(() => import('./pages/auth/SignIn'));
// const SignUp = lazy(() => import('./pages/auth/SignUp'));
// const ResetPassword = lazy(() => import('./pages/auth/ResetPassword'));
const Page404 = lazy(() => import('./pages/auth/Page404'));
const Page500 = lazy(() => import('./pages/auth/Page500'));

const ProjectList = lazy(() => import('./custom/pages/Projects'));
const ProjectDetailsProvider = lazy(() =>
  import('./custom/contexts/ProjectDetailsContext').then(module => ({ default: module.ProjectDetailsProvider })),
);

const ProjectLayout = lazy(() => import('./custom/pages/project/ProjectLayout'));
const Contacts = lazy(() => import('./custom/pages/contacts/Contacts'));
const Leads = lazy(() => import('./custom/pages/contacts/LeadLayout'));
const OpportunityList = lazy(() => import('./custom/pages/OpportunityList'));
const Admin = lazy(() => import('./custom/pages/admin/Admin'));
const Appointments = lazy(() => import('./custom/pages/appointment/Appointments'));
const Inbox = lazy(() => import('./custom/pages/inbox/Inbox'));
// const RequireNewPassword = lazy(() => import('./components/auth/RequireNewPassword'));
const Task = lazy(() => import('./custom/pages/task/Task'));
const Calendar = lazy(() => import('./custom/pages/Calendar'));
const TaskDashboard = lazy(() => import('./custom/pages/dashboard/Task'));
const Appointment = lazy(() => import('./custom/pages/dashboard/Appointment'));
const Chat = lazy(() => import('./pages/pages/Chat'));
const ContactProfile = lazy(() => import('./custom/pages/contacts/ContactProfile'));
const AppointmentDetails = lazy(() => import('./custom/pages/appointment/AppointmentDetails'));
const CompanyManagement = lazy(
  () =>
    import(
      './custom/pages/userProfile/userProfileRightSideDetails/admin/companyManagement/companyDetails/CompanyManagement'
    ),
);
const Users = lazy(
  () => import('./custom/pages/userProfile/userProfileRightSideDetails/admin/userManagement/users/Users'),
);
const RolesAndPermissions = lazy(
  () =>
    import(
      './custom/pages/userProfile/userProfileRightSideDetails/admin/userManagement/rolesAndPermissions/permissions/RolesAndPermissions'
    ),
);
const Integrations = lazy(
  () =>
    import('./custom/pages/userProfile/userProfileRightSideDetails/admin/companyManagement/integrations/Integrations'),
);
const GoodLeap = lazy(
  () =>
    import(
      './custom/pages/userProfile/userProfileRightSideDetails/admin/companyManagement/integrations/configurations/banks/goodLeap/GoodLeap'
    ),
);

const Profile = lazy(() => import('./custom/pages/userProfile/userProfileRightSideDetails/user/profile/Profile'));

const LazyLoadComponent = (Component: ComponentType<any>) => (
  <Suspense fallback={<NucleusLoader />}>
    <Component />
  </Suspense>
);

const routes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <Navigate to="/crm" replace />
      </AuthGuard>
    ),
  },
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <NucleusDashboard />
      </AuthGuard>
    ),
    children: [
      { path: '', element: LazyLoadComponent(Admin) },
      {
        path: 'phone-numbers/buy',
        element: (
          <PermissionGuard requiredPermission="admin:twilio:view-available-phone-numbers">
            {LazyLoadComponent(AvailableTwilioNumbersList)}
          </PermissionGuard>
        ),
      },
      ...(process.env.NODE_ENV !== 'production'
        ? [
            {
              path: 'phone-numbers/active',
              element: (
                <PermissionGuard requiredPermission="admin:twilio:view-purchased-phone-numbers">
                  {LazyLoadComponent(PurchasedNumbersList)}
                </PermissionGuard>
              ),
            },
          ]
        : []),
    ],
  },
  {
    path: 'proposal',
    element: LazyLoadComponent(BlankLayout),
    children: [
      {
        path: ':id',
        element: <ViewProposal />,
      },
    ],
  },
  {
    path: 'crm',
    element: (
      <AuthGuard>
        <NucleusDashboard />
      </AuthGuard>
    ),
    children: [
      { path: '', element: <Navigate to="/crm/contacts" replace /> },
      {
        path: 'dashboard/task',
        element: LazyLoadComponent(Task),
      },
      {
        path: 'leads',
        children: [
          {
            path: '',
            element: LazyLoadComponent(Leads),
          },
        ],
      },
      {
        path: 'contacts',
        children: [
          {
            path: '',
            element: LazyLoadComponent(Contacts),
          },
          {
            path: ':contactId',
            element: LazyLoadComponent(ContactProfile),
          },
        ],
      },
      {
        path: 'dashboard/appointment',
        element: LazyLoadComponent(Appointment),
      },
      {
        path: 'inbox',
        element: LazyLoadComponent(Inbox),
        children: [
          {
            path: ':paramConversationSid',
            element: LazyLoadComponent(Inbox),
          },
        ],
      },

      {
        path: 'calendar',
        element: LazyLoadComponent(Calendar),
      },
      {
        path: 'appointments',
        element: LazyLoadComponent(Appointments),
      },
      {
        path: 'opportunities',
        element: LazyLoadComponent(OpportunityList),
      },
      {
        path: 'tasks',
        children: [
          {
            path: '',
            element: LazyLoadComponent(Task),
          },
          {
            path: ':id',
            element: LazyLoadComponent(Task),
          },
        ],
      },
      {
        path: 'chat',
        element: LazyLoadComponent(Chat),
      },
      {
        path: 'analytics/task',
        element: LazyLoadComponent(TaskDashboard),
      },
      {
        path: 'appointments',
        children: [
          {
            path: '',
            element: LazyLoadComponent(Appointments),
          },
          {
            path: ':appointmentId',
            element: LazyLoadComponent(AppointmentDetails),
          },
        ],
      },
      {
        path: 'new-contact',
        element: LazyLoadComponent(NewContact),
      },

      {
        path: 'new-lead',
        element: LazyLoadComponent(NewLead),
      },
      {
        path: 'user-profile',
        element: <UserProfile />,
        children: [
          { path: '', element: <Navigate to="/crm/user-profile/user/profile" replace /> },
          {
            path: 'user',
            element: LazyLoadComponent(BlankLayout),
            children: [
              {
                path: 'profile',
                element: LazyLoadComponent(Profile),
              },
            ],
          },
          {
            path: 'admin',
            element: LazyLoadComponent(BlankLayout),
            children: [
              {
                path: 'company-management',
                children: [
                  {
                    path: '',
                    element: <Navigate to="/crm/user-profile/admin/company-management/company-details" replace />,
                  },
                  {
                    path: 'company-details',
                    element: LazyLoadComponent(CompanyManagement),
                  },
                  {
                    path: 'integrations',
                    children: [
                      {
                        path: '',
                        element: LazyLoadComponent(Integrations),
                      },
                      {
                        path: ':bank',
                        element: LazyLoadComponent(GoodLeap),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'user-management',
                children: [
                  { path: '', element: <Navigate to="/crm/user-profile/admin/user-management/users" replace /> },
                  {
                    path: 'users',
                    element: LazyLoadComponent(Users),
                  },
                  {
                    path: 'roles-and-permissions',
                    element: LazyLoadComponent(RolesAndPermissions),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'calls/voicemails',
        element: LazyLoadComponent(Voicemail),
        children: [
          {
            path: ':paramVoicemailSid',
            element: LazyLoadComponent(Voicemail),
          },
        ],
      },
      {
        path: 'calls/missed-calls',
        element: LazyLoadComponent(MissedCall),
        children: [
          {
            path: ':paramMissedCallId',
            element: LazyLoadComponent(MissedCall),
          },
        ],
      },
      {
        path: 'calls/call-activity',
        element: LazyLoadComponent(CallActivity),
      },
    ],
  },
  {
    path: 'servicing',
    element: (
      <AuthGuard>
        <NucleusDashboard />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: LazyLoadComponent(() => <ProjectList completed={true} title={'Servicing'} />),
      },
    ],
  },
  {
    path: 'projects',
    element: (
      <AuthGuard>
        <NucleusDashboard />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: LazyLoadComponent(() => <ProjectList completed={false} title={'Projects'} />),
      },
      {
        path: 'project-details/:id',
        element: LazyLoadComponent(() => (
          <ProjectDetailsProvider>
            <ProjectMenuNew />
          </ProjectDetailsProvider>
        )),
        children: [
          {
            path: ':phase',
            children: [
              {
                path: '',
                element: LazyLoadComponent(ProjectLayout),
              },
              {
                path: ':projectManagementRequirementId',
                element: LazyLoadComponent(ProjectLayout),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'auth',
    element: LazyLoadComponent(AuthLayout),
    children: [
      {
        path: 'sign-in',
        element: LazyLoadComponent(SignIn),
      },
      // {
      //   path: 'sign-up',
      //   element: LazyLoadComponent(SignUp),
      // },
      // {
      //   path: 'reset-password',
      //   element: LazyLoadComponent(ResetPassword),
      // },
      // {
      //   path: 'new-password',
      //   element: LazyLoadComponent(RequireNewPassword),
      // },
      {
        path: '404',
        element: LazyLoadComponent(Page404),
      },
      {
        path: '500',
        element: LazyLoadComponent(Page500),
      },
    ],
  },
  {
    path: '*',
    element: LazyLoadComponent(AuthLayout),
    children: [
      {
        path: '*',
        element: LazyLoadComponent(Page404),
      },
    ],
  },
];

export default routes;
